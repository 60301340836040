import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What are you starting from?"
    options={[
      {
        label: "I am starting from scratch",
        link: "/app/digital-product-design/hi-fi/design-from-scratch/"
      },
      {
        label: "I have some paper wireframes",
        link: "/app/digital-product-design/hi-fi/design-from-paper-wireframes/"
      },
      {
        label: "I have an old design made in Sketch",
        link: "/app/digital-product-design/hi-fi/design-from-sketch/"
      },
      {
        label: "I have some wireframes made in a software",
        link: "/app/digital-product-design/hi-fi/design-from-digital-wireframes/"
      }
  ]}/>
)

export default Picker;


